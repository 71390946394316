import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';
import ErrorComp from 'components/error';
import nextI18NextConfig from '../next-i18next.config';

const Index = () => <ErrorComp statusCode={404} />;

export const getStaticProps: GetStaticProps = async (context) => {
  const { locale } = context;

  return {
    props: {
      ...(await serverSideTranslations(
        locale as string,
        ['common', 'wedding', 'countries'],
        nextI18NextConfig,
      )),
    },
  };
};

export default Index;
